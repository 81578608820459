<template>
  <v-card>
    <page>
      <v-card light rounded="lg" class="pa-10 text-center">
        {{ getText() }}
      </v-card>
    </page>
  </v-card>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page";

@Component({
  components: { Page }
})
class FailPayment extends Vue {
  getText() {
    return 'Отказ в проведении безналичной оплаты';
  }
}
export default FailPayment;
</script>

<style scoped>

</style>